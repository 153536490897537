"use client";

import { useEffect } from "react";

const ScrollRestorationController = () => {
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return null;
};

export { ScrollRestorationController };
