import type {
  AdRuleType,
  AstAdObj,
  AstApnTag,
  AstKeywordValue,
  EidsUserSource,
  RelevantYield,
  ViewType,
} from "@omni/ads";

import { layoutNames, sharedVars, specialCharacters } from "../constants";
import { getPulseTracker } from "../../../../pulse";

function getBiddingSdk(): Promise<RelevantYield> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const biddingSdk = window?.relevantDigital;
      if (biddingSdk) {
        resolve(biddingSdk);
      } else {
        resolve(getBiddingSdk());
      }
    }, 100);
  });
}

const getUserIds = async () => {
  const ppIds = await getPPIDs();
  const eids = [];

  if (ppIds?.some((ppid) => ppid.source === "SCHSE-EnvHash")) {
    const PPID2 = ppIds.filter((ppid) => ppid.source === "SCHSE-EnvHash");

    eids.push({
      source: "SCHNO-EnvHash",
      uids: [
        {
          id: PPID2[0].id,
          atype: 1,
          ext: {
            stype: "ppuid",
          },
        },
      ],
    });
  }
  if (ppIds?.some((ppid) => ppid.source === "SCHSE-UserHash")) {
    const PPID1 = ppIds.filter((ppid) => ppid.source === "SCHSE-UserHash");

    eids.push({
      source: "SCHNO-UserHash",
      uids: [
        {
          id: PPID1[0].id,
          atype: 1,
          ext: {
            stype: "ppuid",
          },
        },
      ],
    });
  }

  return [
    {
      name: "pubProvidedId",
      params: {
        eids: eids,
      },
    },
  ];
};

function getApnTag(): Promise<AstApnTag> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const windowApnTag = window?.apntag;
      if (windowApnTag) {
        resolve(windowApnTag);
      } else {
        resolve(getApnTag());
      }
    }, 100);
  });
}

const getGenericExternalId = ({
  adId,
  loggedIn,
}: {
  adId?: string;
  loggedIn: boolean;
}): number => {
  if (!adId) return 0;
  if (!loggedIn) return 1;
  return 2;
};

const getPPIDs = async (): Promise<EidsUserSource[]> => {
  const pulse = getPulseTracker();
  try {
    const identifiers = await pulse?.pulseTracker.getAdvertisingIdentifiers();

    if (identifiers?.xandr) {
      const { ppId1, ppId2 } = identifiers.xandr;

      return ppId1
        ? [
            { id: `${ppId1}`, source: "SCHSE-UserHash" },
            { id: `${ppId2}`, source: "SCHSE-EnvHash" },
          ]
        : [{ id: `${ppId2}`, source: "SCHSE-EnvHash" }];
    }
  } catch (e) {
    console.error(e);
  }

  return [];
};

async function getAdId() {
  const pulse = getPulseTracker();
  const identifiers = await pulse?.pulseTracker.getAdvertisingIdentifiers();

  if (identifiers?.xandr) {
    const { adId } = identifiers.xandr;
    return adId;
  }

  return undefined;
}

const getPPIDWithTagValues = async (): Promise<
  [EidsUserSource[], Record<string, AstKeywordValue>]
> => {
  const ppIds = await getPPIDs();

  const ppidTags = { "aa-sch-schuserhash": 0, "aa-sch-schenvhash": 0 };

  if (ppIds?.some((ppid: any) => ppid.source === "SCHSE-UserHash")) {
    ppidTags["aa-sch-schuserhash"] = 1;
  }

  if (ppIds?.some((ppid: any) => ppid.source === "SCHSE-EnvHash")) {
    ppidTags["aa-sch-schenvhash"] = 1;
  }

  return [ppIds, ppidTags];
};

const sortedScreens = Object.keys(sharedVars)
  .map((key) => ({ key, value: sharedVars[key] }))
  .filter(({ key }) => /^screen-/.test(key))
  .sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    } else if (a.value > b.value) {
      return 1;
    } else {
      return 0;
    }
  });

const getCurrentBreakpoint = () => {
  const windowWidth = window.innerWidth;
  return sortedScreens.reduce((a, b) => (b.value <= windowWidth ? b : a), {
    key: "screen-xs-min",
    value: 0,
  }).key;
};

const getLayout = () => layoutNames[getCurrentBreakpoint()];

const getSupplyType = () => {
  const layout = getLayout();
  return `web_${layout === "mobile" ? "phone" : layout}`;
};

const windowKeyWords = () => {
  const entries = new URLSearchParams(window.location.search).entries();
  const acc: { [key: string]: string } = {};
  let kv: IteratorResult<[string, string]>;
  while ((kv = entries.next())) {
    if (kv.done) {
      break;
    }
    const [key, val] = (kv.value || []) as [string?, string?];
    if (val && key?.startsWith("kv_")) {
      acc[key.substring(3)] = val;
    }
  }
  return acc;
};

const sanitizeValue = (val = "") =>
  val
    .replace(specialCharacters, "")
    .replace(/[åä]/g, "a")
    .replace(/[ö]/g, "o")
    .replace(/[ÅÄ]/g, "A")
    .replace(/[Ö]/g, "O")
    .replace(/&/g, "--")
    .replace(/[,/\s]/g, "-");

const sizeToArray = ({ width, height }: { width: number; height: number }) => [
  width,
  height,
];

const isNativeBanner = (e: AstAdObj) => e.adType === "banner";

const isNativeAd = (e: AstAdObj) => e.adType === "native";

const getFeedSplitIndex = (context: ViewType, adRules: AdRuleType[]) => {
  const fullscreen = adRules.filter(
    (adRule) =>
      adRule.rules.position === "fullscreen" &&
      adRule.rules.views?.includes(context),
  );
  return fullscreen.length > 0 ? fullscreen[0]?.rules?.index : 10;
};

const removePageContextFromId = (id: string) => {
  return id.replace("-feed", "").replace("-article", "");
};

export {
  getApnTag,
  getAdId,
  getBiddingSdk,
  getUserIds,
  getPPIDWithTagValues,
  isNativeAd,
  isNativeBanner,
  sanitizeValue,
  getSupplyType,
  sizeToArray,
  getCurrentBreakpoint,
  getLayout,
  getGenericExternalId,
  windowKeyWords,
  getFeedSplitIndex,
  removePageContextFromId,
};
